import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "value", "row", "output" ]

  update() {
    const output = this.names.map((name, i) => {
      return [ name, this.values[i] ]
    })

    this.outputTarget.value = JSON.stringify(output)
  }

  addRow() {
    let newRow = this.rowTarget.cloneNode(true)
    newRow.querySelectorAll("input").forEach(node => node.value = "")
    this.rowTargets[this.rowTargets.length -1].insertAdjacentElement("afterend", newRow)

    this.update()
  }

  deleteRow() {
    if (this.rowTargets.length > 1) {
      let curRow

      for (let row of this.rowTargets) {
        if (row.contains(event.target)) {
          curRow = row
          break
        }
      }
      curRow.remove()
    }
    else {
      this.rowTarget.querySelectorAll("input").forEach(input => input.value = "")
    }

    this.update()
  }

  get names() {
    return this.nameTargets.map(nameTarget => nameTarget.value)
  }

  get values() {
    return this.valueTargets.map(valueTarget => valueTarget.value)
  }
}
