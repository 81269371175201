import { Controller } from "stimulus"

export default class extends Controller {
  stripeCheckout() {
    event.preventDefault()
    const stripe = window.Stripe(this.stripeKey)

    fetch("/stripe/checkout", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": this.authToken, // this is required to avoid an InvalidAuthToken error
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) document.querySelector('#form_errors').innerText = Object.values(response)
        return response
      })
      .then(response => response.json())
      .then(session => {
        stripe.redirectToCheckout({ sessionId: session.id })
      })
      .then(result => {
        // TODO: If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  get stripeKey() {
    if (process.env.NODE_ENV == "development") return process.env.CM_DEV_STRIPE_PUB_KEY
    return process.env.CM_PROD_STRIPE_PUB_KEY
  }

  get authToken() {
    return this.element.querySelector("[name=authenticity_token]").value
  }
}
