import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "menuButton", "sectionButton" ]

  initialize() {
    this.menuActive = false
    for (let sectionButton of this.sectionButtonTargets) {
      sectionButton.dataTarget = sectionButton.nextElementSibling
      this.sectionActive(sectionButton, false)
    }
  }

  toggleMenu() {
    this.menuActive = !(this.menuActive)
  }

  toggleSection() {
    this.sectionActive(event.target, event.target.dataset.active != "true")
  }

  sectionActive(sectionButton, newState) {
    this.setActive([sectionButton, sectionButton.dataTarget], newState)
  }

  setActive(els, state) {
    if (!Array.isArray(els))
      els = [els]

    for (let el of els)
      el.dataset.active = state
  }

  get menuActive() {
    return this.menuTarget.dataset.active === "true"
  }

  set menuActive(newState) {
    this.setActive([this.menuTarget, this.menuButtonTarget], newState)
  }
}
