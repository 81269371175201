// setup polyfills for smoothscroll
import './app'

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start() // what does this do?

// initialise stimulus
// import "./controllers"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Configure Stimulus development experience
application.debug = true
window.Stimulus   = application

export { application }
