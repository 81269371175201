import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  connect() {
    smoothscroll.polyfill()
    useIntersection(this)
  }

  refresh() {
    this.element.requestSubmit()
  }

  scrollTo(event) {
    let target = document.querySelector(event.target.dataset.scrollTarget)
    target.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  autoClick(event) {
    let target = document.querySelector(event.target.dataset.clickTarget)
    target.click()
  }

  testing(event) {
    console.log("I HAVE ARRIVED")
  }
}
